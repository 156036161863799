import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';
import type { ContextParams } from '@wix/platform-editor-sdk';

import type { PrivateAPI } from '../../types';
import { HistoryLabel } from '../../constants';
import { updateMembersSubMenuItemsOrder } from '../../editor/services/members-sub-menu';
import { updateMenusItemLabel } from '../../editor/services/menus-wrapper';
import { monitoredTransactionFactory } from '../../editor/services/monitor';
import { withHistoryFactory } from '../../editor/editor-sdk-wrappers';

import {
  getLayoutPreset,
  openMemberSettingsBM,
  RoutesConfigurationServiceEditor,
  setLayoutPreset,
} from '../../editor/services';
import { reorderRoutes } from '../../editor/controller/controllers-routes';

const initPrivateAPI = (
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
): PrivateAPI => {
  const monitoredTransaction = monitoredTransactionFactory(editorSDK);
  const withHistory = withHistoryFactory(editorSDK);
  const routeService = new RoutesConfigurationServiceEditor(
    contextParams.essentials.httpClient,
  );

  return {
    getLayoutPreset: () => getLayoutPreset(editorSDK),
    setLayoutPreset: (layoutPreset) =>
      monitoredTransaction('private-api.set-layout-preset', () =>
        setLayoutPreset(editorSDK, layoutPreset),
      ),
    updateMenusItemsOrder: (sourceIndex: number, targetIndex: number) =>
      monitoredTransaction(
        'private-api.update-members-sub-menu-items-order',
        async () => {
          const updateOrderPromise = updateMembersSubMenuItemsOrder(
            editorSDK,
            sourceIndex,
            targetIndex,
          );
          const reorderRoutesPromise = reorderRoutes(
            {
              editorSDK,
              sourceIndex,
              targetIndex,
            },
            { routeService },
          );

          await withHistory(HistoryLabel.MenuItemsReordered, () =>
            Promise.all([updateOrderPromise, reorderRoutesPromise]),
          );
        },
      ),
    updateMenusItemLabel: (widgetId: WidgetId, newLabel: string) =>
      monitoredTransaction('private-api.update-menus-item-label', () =>
        withHistory(HistoryLabel.MenuItemRenamed, () =>
          updateMenusItemLabel(editorSDK, widgetId, newLabel),
        ),
      ),
    openMemberSettingsBM: (referralInfo) =>
      openMemberSettingsBM(editorSDK, referralInfo),
  };
};

export const createPrivateAPI = (
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
): PrivateAPI => {
  const getPrivateAPI = (() => {
    let _privateAPI: PrivateAPI;
    return async () => {
      if (_privateAPI) {
        return _privateAPI;
      }
      _privateAPI = initPrivateAPI(editorSDK, contextParams);
      return _privateAPI;
    };
  })();

  return {
    getLayoutPreset: async () => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.getLayoutPreset();
    },
    setLayoutPreset: async (layoutPreset) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.setLayoutPreset(layoutPreset);
    },
    updateMenusItemsOrder: async (sourceIndex: number, targetIndex: number) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updateMenusItemsOrder(sourceIndex, targetIndex);
    },

    updateMenusItemLabel: async (widgetId: WidgetId, newLabel: string) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.updateMenusItemLabel(widgetId, newLabel);
    },
    openMemberSettingsBM: async (referralInfo) => {
      const privateAPI = await getPrivateAPI();
      return privateAPI.openMemberSettingsBM(referralInfo);
    },
  };
};
