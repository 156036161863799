import type { CollapsedExpandedComponentRef } from '@wix/platform-editor-sdk';
import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const collapseRefComponent = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
) => {
  await editorSDK.document.components.refComponents.collapseReferredComponent(
    '',
    { componentRef },
  );
};

export const expandRefComponentByRole = async (
  editorSDK: FlowEditorSDK,
  role: string,
) => {
  const refApi = editorSDK.document.components.refComponents;
  const [hostRef] = await refApi.getAllAppRefComponents('');

  // We're seeing undefined values instead of empty array coming from platform - fixing return type
  // To do: track the cases when this happens and report
  const allCollapsedComponents: (CollapsedExpandedComponentRef | undefined)[] =
    await refApi.getCollapsedRefComponents('', {
      componentRef: hostRef,
    });

  const collapsedCompToRestore = allCollapsedComponents.find(
    (comp) => comp?.role === role,
  );

  if (collapsedCompToRestore?.componentRef) {
    refApi.expandReferredComponent('', {
      componentRef: collapsedCompToRestore.componentRef,
    });
  }
};

export const isRefComponentCollapsed = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
) => {
  const refApi = editorSDK.document.components.refComponents;
  return refApi.isRefComponentCollapsed('', { componentRef });
};
