import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { addWidgetOptions } from '@wix/platform-editor-sdk';
import { Props } from '../../types';

export const addWidget = async (
  editorSDK: FlowEditorSDK,
  options: addWidgetOptions,
) => editorSDK.application.appStudioWidgets.addWidget('', options);

export const setProps = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  newProps: Props,
) => {
  return editorSDK?.document.application.appStudioWidgets.props.set('', {
    widgetRef,
    newProps,
  });
};
