import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import { getMenuItems, updateMenuItems } from '../editor-sdk-wrappers/menu';
import { MenuId } from '../../constants';
import { getReorderedItems } from '../../utils';
import { getMenuItemsWithReplacedMembersPath } from './utils';
import { EditorMenuItem } from '../../types';

export const getMembersSubMenuItems = (editorSDK: FlowEditorSDK) => {
  return getMenuItems(editorSDK, MenuId.MembersSubMenu);
};

export const getWidgetIdsSetFromSubMenu = async (editorSDK: FlowEditorSDK) => {
  const subMenuItems = await getMembersSubMenuItems(editorSDK);

  return new Set(
    subMenuItems
      .map((menuItem) => menuItem?.link?.itemId as WidgetId)
      .filter(Boolean),
  );
};

export const getWidgetIdsFromSubMenu = async (
  editorSDK: FlowEditorSDK,
): Promise<WidgetId[]> => {
  const subMenuItems = await getMembersSubMenuItems(editorSDK);
  return subMenuItems
    .map((menuItem) => menuItem?.link?.itemId as WidgetId)
    .filter(Boolean);
};

export const updateMembersSubMenuItems = (
  editorSDK: FlowEditorSDK,
  items: EditorMenuItem[],
) => {
  return updateMenuItems(editorSDK, MenuId.MembersSubMenu, items);
};

export const removeMembersSubMenuItem = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
) => {
  const subMenuItems = await getMembersSubMenuItems(editorSDK);

  const filteredSubMenuItems = subMenuItems.filter(
    (menuItem) => menuItem?.link?.itemId !== widgetId,
  );

  return updateMembersSubMenuItems(editorSDK, filteredSubMenuItems);
};

export const updateMembersSubMenuItemsOrder = async (
  editorSDK: FlowEditorSDK,
  sourceIndex: number,
  targetIndex: number,
) => {
  const subMenuItems = await getMembersSubMenuItems(editorSDK);

  const reorderedSubMenuItems = getReorderedItems<EditorMenuItem>(
    sourceIndex,
    targetIndex,
    subMenuItems,
  );

  return updateMembersSubMenuItems(editorSDK, reorderedSubMenuItems);
};

export const updateMembersSubMenuItemLabel = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
  newLabel: string,
) => {
  const subMenuItems = await getMembersSubMenuItems(editorSDK);

  const updatedSubMenuItems = subMenuItems.map((menuItem) => {
    return menuItem?.link?.itemId === widgetId
      ? { ...menuItem, label: newLabel }
      : menuItem;
  });

  return updateMembersSubMenuItems(editorSDK, updatedSubMenuItems);
};

export const updateMembersSubMenuMembersPath = async (
  editorSDK: FlowEditorSDK,
  currentPageUriSEO: string,
  newPageUriSEO: string,
) => {
  const subMenuItems = await getMembersSubMenuItems(editorSDK);

  const updatedSubMenuItems = getMenuItemsWithReplacedMembersPath(
    subMenuItems,
    currentPageUriSEO,
    newPageUriSEO,
  );

  return updateMembersSubMenuItems(editorSDK, updatedSubMenuItems);
};
