import { addWidgetOptions } from '@wix/platform-editor-sdk';

export const EDITOR_X_WIDGET_LAYOUT: addWidgetOptions['layouts'] = {
  itemLayout: {
    id: '',
    alignSelf: 'start',
    justifySelf: 'center',
    type: 'GridItemLayout',
    gridArea: {
      rowStart: 2,
      rowEnd: 3,
      columnStart: 1,
      columnEnd: 2,
    },
  },
  componentLayout: {
    type: 'ComponentLayout',
    maxWidth: {
      type: 'px',
      value: 980,
    },
    width: {
      type: 'percentage',
      value: 100,
    },
    height: {
      type: 'auto',
    },
  },
};
