import { FlowEditorSDK, IHttpClient } from '@wix/yoshi-flow-editor';
import {
  bulkCreateRouteConfiguration,
  bulkDeleteRouteConfigurationByWidgetId,
  queryRouteConfigurations,
  reorderRouteConfigurations,
  updateRouteConfigurationByWidgetId,
} from '@wix/ambassador-members-routes-v1-route-configuration/http';
import { UpdateRouteConfigurationByWidgetIdRequest } from '@wix/ambassador-members-routes-v1-route-configuration/types';
import { WidgetId } from '@wix/members-area-app-definitions';

import type {
  AddedWidgetConfig,
  RouteConfiguration,
  RoutesConfigurationServiceEditor as IRoutesConfigurationServiceEditor,
} from '../../../types';
import { getMultiStateBoxStatesWidgetsIdsToStateMap } from '../multi-state-box';
import { getReorderedItems } from '../../../utils';

export class RoutesConfigurationServiceEditor
  implements IRoutesConfigurationServiceEditor
{
  constructor(private httpClient: IHttpClient) {}

  async fetchRouteConfigurations() {
    const { data } = await this.httpClient.request(
      queryRouteConfigurations({}),
    );

    return (data.routeConfigurations ?? []) as RouteConfiguration[];
  }

  async updateRouteConfiguration(
    payload: UpdateRouteConfigurationByWidgetIdRequest,
  ) {
    const { data } = await this.httpClient.request(
      updateRouteConfigurationByWidgetId(payload),
    );

    return (data.routeConfiguration ?? {}) as RouteConfiguration;
  }

  async bulkCreateRouteConfigurations(
    editorSDK: FlowEditorSDK,
    addedWidgetsConfigs: AddedWidgetConfig[],
  ) {
    const routeConfigurations = await this.getNewRoutesWithMultiStateBoxStates(
      editorSDK,
      addedWidgetsConfigs,
    );

    const { data } = await this.httpClient.request(
      bulkCreateRouteConfiguration({ routeConfigurations }),
    );

    return data;
  }

  async bulkDeleteRouteConfigurations(widgetIds: WidgetId[]) {
    const { data } = await this.httpClient.request(
      bulkDeleteRouteConfigurationByWidgetId({
        routeConfigurationWidgetIds: widgetIds,
      }),
    );

    return data;
  }

  async reorderRouteConfigurations(sourceIndex: number, targetIndex: number) {
    const routeConfigurations = await this.fetchRouteConfigurations();
    const reorderedRouteConfigurations = getReorderedItems<RouteConfiguration>(
      sourceIndex,
      targetIndex,
      routeConfigurations,
    );
    const routeConfigurationIds = reorderedRouteConfigurations.map(
      (route) => route.id!,
    );

    const { data } = await this.httpClient.request(
      reorderRouteConfigurations({ routeConfigurationIds }),
    );

    return (data.routeConfigurations ?? []) as RouteConfiguration[];
  }

  private async getNewRoutesWithMultiStateBoxStates(
    editorSDK: FlowEditorSDK,
    addedWidgetsConfigs: AddedWidgetConfig[],
  ): Promise<RouteConfiguration[]> {
    const widgetIdToStateMap = await getMultiStateBoxStatesWidgetsIdsToStateMap(
      editorSDK,
    );
    return addedWidgetsConfigs.map((route) => ({
      ...route,
      state: widgetIdToStateMap[route.widgetId],
    }));
  }
}
