export const getReorderedItems = <T>(
  sourceIndex: number,
  targetIndex: number,
  items: T[],
) => {
  const itemsCopy = [...items];
  const [itemToAdd] = itemsCopy.splice(sourceIndex, 1);

  itemsCopy.splice(targetIndex, 0, itemToAdd);

  return itemsCopy;
};
