import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import type {
  EditorContextServices,
  RouteConfiguration,
  RoutesConfigurationServiceEditor,
  UpdateRouteConfigurationRequest,
} from '../../types';

import { Experiment } from '../../constants';
import { refreshApp } from '../editor-sdk-wrappers';
import { shouldUseNewRoutesService } from '../services/utils';
import { updateMemberPageControllerWithRoutes } from './member-page-controller';
import { globalAppState } from '../services';

import {
  getRoutesFromGlobalController,
  updateGlobalControllerWithRoutes,
} from './global-controller';
import { maybeNavigateToMemberPage } from '../services/navigation';
import { log } from '../services/monitor';

type ReorderRoutesContextProps = {
  editorSDK: FlowEditorSDK;
  sourceIndex: number;
  targetIndex: number;
};

export const getRoutes = async (
  editorSDK: FlowEditorSDK,
  routeService: RoutesConfigurationServiceEditor,
) => {
  if (await shouldUseNewRoutesService(editorSDK)) {
    return routeService.fetchRouteConfigurations();
  }

  return getRoutesFromGlobalController(editorSDK);
};

export const updateRoutesConfig = async (
  editorSDK: FlowEditorSDK,
  updatedRoutes: RouteConfiguration[],
) => {
  await updateGlobalControllerWithRoutes(editorSDK, updatedRoutes);

  const avoidNavigation = globalAppState.getIsClassicEditor();
  if (avoidNavigation) {
    return;
  }

  const { experiments } = globalAppState.getFlowAPI() || {};
  if (!experiments?.enabled(Experiment.DontWaitForBoBController)) {
    await updateMemberPageControllerWithRoutes(editorSDK, updatedRoutes);
    return;
  }

  // Updating BoB controller should be unecessary after global controller is available everywhere
  // Until then this is for fallback for pages without a header
  await maybeNavigateToMemberPage(editorSDK);

  updateMemberPageControllerWithRoutes(editorSDK, updatedRoutes).catch(
    (e: any) => {
      log('Warning: Failed to update BoB controller', {
        extra: {
          error: typeof e === 'string' ? e : (e as Error).message,
        },
      });
    },
  );
};

export const updateRouteInController = async (
  editorSDK: FlowEditorSDK,
  updatedRoute: RouteConfiguration,
) => {
  const routes = await getRoutesFromGlobalController(editorSDK);

  if (!routes.length) {
    return updatedRoute;
  }

  const updatedRoutes = routes.map((route) => {
    return route.widgetId === updatedRoute.widgetId
      ? { ...route, ...updatedRoute }
      : route;
  });

  await updateRoutesConfig(editorSDK, updatedRoutes);
  await refreshApp(editorSDK);

  return updatedRoute;
};

export const updateRoute = async (
  editorSDK: FlowEditorSDK,
  routeService: RoutesConfigurationServiceEditor,
  payload: UpdateRouteConfigurationRequest,
) => {
  if (await shouldUseNewRoutesService(editorSDK)) {
    return routeService.updateRouteConfiguration(payload);
  }

  return updateRouteInController(editorSDK, payload.routeConfiguration);
};

const removeRoute = async (editorSDK: FlowEditorSDK, widgetId: string) => {
  const routes = await getRoutesFromGlobalController(editorSDK);
  if (!routes.length) {
    return;
  }

  const filteredRoutes = routes.filter((route) => route.widgetId !== widgetId);

  return updateRoutesConfig(editorSDK, filteredRoutes);
};

const removeRoutesFromController = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  // TODO: use removeRoutesFromGlobalController - no need to update in loop
  for (const widgetId of widgetsIds) {
    await removeRoute(editorSDK, widgetId);
  }
};

export const removeRoutes = async (
  editorSDK: FlowEditorSDK,
  routeService: RoutesConfigurationServiceEditor,
  widgetsIds: WidgetId[],
) => {
  if (await shouldUseNewRoutesService(editorSDK)) {
    return routeService.bulkDeleteRouteConfigurations(widgetsIds);
  }

  return removeRoutesFromController(editorSDK, widgetsIds);
};

export const reorderRoutes = async (
  { editorSDK, sourceIndex, targetIndex }: ReorderRoutesContextProps,
  { routeService }: Pick<EditorContextServices, 'routeService'>,
) => {
  if (await shouldUseNewRoutesService(editorSDK)) {
    return routeService.reorderRouteConfigurations(sourceIndex, targetIndex);
  }
};
