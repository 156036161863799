import { Experiments, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { getInstalledPluginsSlots } from '../../editor/services/slots';
import { Experiment } from '../../constants';
import { toMonitored } from '../../editor/services/monitor';
import { buildRoutesFromSlots } from '../../editor/controller/routes-utils';

import {
  isControllerWithInvalidRoutes,
  updateGlobalControllerWithRoutes,
} from '../../editor/controller/global-controller';

export const populateGlobalControllerWithRoutes = async (
  editorSDK: FlowEditorSDK,
) => {
  const slots = await getInstalledPluginsSlots(editorSDK);
  const routes = await buildRoutesFromSlots(editorSDK, slots);

  return updateGlobalControllerWithRoutes(editorSDK, routes);
};

export const shouldFixGlobalControllerMissingRoutesData = (
  editorSDK: FlowEditorSDK,
  experimentsAPI: Experiments,
) => {
  return (
    experimentsAPI.enabled(Experiment.EnableGlobalControllerDataFixer) &&
    isControllerWithInvalidRoutes(editorSDK)
  );
};

export const monitoredFixGlobalControllerMissingRoutesData = async (
  editorSDK: FlowEditorSDK,
) => {
  return toMonitored(
    'editorReady.fix-global-controller-missing-routes-data',
    async () => populateGlobalControllerWithRoutes(editorSDK),
  );
};
