import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { PrivateAPI } from '../../types';

export const getPrivateAPI = async (
  editorSDK: FlowEditorSDK,
): Promise<PrivateAPI> => {
  const api = await editorSDK.editor.getAppAPI();

  if (!api) {
    throw new Error(`Failed to get private API for Profile Page Bob`);
  }

  return api;
};

export const openDashboardPanel = async (
  editorSDK: FlowEditorSDK,
  url: string,
  closeOtherPanels: boolean = true,
) => {
  return editorSDK.editor.openDashboardPanel('', {
    url,
    closeOtherPanels,
  });
};
