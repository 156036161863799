import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import {
  DynamicPageLink,
  PageData,
  PageLink,
  PageRef,
} from '@wix/platform-editor-sdk';

import { PagesOptions } from '../../types';
import { getMembersAreaPageRef } from '../services/page';

interface PageRenameOptions {
  title: string;
  pageRef: PageRef;
}

export const addPage = async (
  editorSDK: FlowEditorSDK,
  options: PagesOptions,
) => editorSDK.pages.add('', options);

export const renamePage = async (
  editorSDK: FlowEditorSDK,
  options: PageRenameOptions,
) => editorSDK.pages.rename('', options);

export const updatePageData = (
  editorSDK: FlowEditorSDK,
  options: { pageRef: PageRef; data: Partial<PageData> },
) => editorSDK.pages.data.update('', options);

export const navigateTo = (
  editorSDK: FlowEditorSDK,
  options: { pageLink: PageLink | DynamicPageLink },
) => editorSDK.pages.navigateTo('', options);

export const getCurrent = (editorSDK: FlowEditorSDK) =>
  editorSDK.pages.getCurrent('');

export const getCurrentPageId = async (editorSDK: FlowEditorSDK) => {
  const { id } = await getCurrent(editorSDK);
  return id;
};

export const getPageData = (editorSDK: FlowEditorSDK, pageRef: PageRef) =>
  editorSDK.pages.getPageData('', { pageRef });

export const getPageUriSEO = async (editorSDK: FlowEditorSDK) => {
  const pageRef = await getCurrent(editorSDK);
  const { pageUriSEO } = await getPageData(editorSDK, pageRef);

  return pageUriSEO;
};

export const getMemberPageUriSEO = async (editorSDK: FlowEditorSDK) => {
  const pageRef = await getMembersAreaPageRef(editorSDK);
  const { pageUriSEO } = await getPageData(editorSDK, pageRef);

  return pageUriSEO;
};
