import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { refreshApp } from './document';
import { MenuId } from '../../constants';
import { EditorMenuItem } from '../../types';

const getMenu = (editorSDK: FlowEditorSDK, menuId: string) => {
  return editorSDK.menu.getById('', { menuId });
};

export const getMenuItems = async (
  editorSDK: FlowEditorSDK,
  menuId: MenuId,
) => {
  const menu = await getMenu(editorSDK, menuId);

  if (!menu) {
    throw new Error('Could not retrieve the menu ' + menuId);
  }

  return menu.items as EditorMenuItem[];
};

const updateMenu = async (
  editorSDK: FlowEditorSDK,
  menuId: string,
  menuData: object,
) => {
  await editorSDK.menu.update('', { menuId, menuData });

  return refreshApp(editorSDK);
};

export const updateMenuItems = async (
  editorSDK: FlowEditorSDK,
  menuId: string,
  items: EditorMenuItem[],
) => {
  const menuData = await getMenu(editorSDK, menuId);
  const updatedMenuData = { ...menuData, items };

  return updateMenu(editorSDK, menuId, updatedMenuData);
};
