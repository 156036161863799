import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { AppManifestEventHandler } from '@wix/app-manifest-builder/dist/types/events/eventHandlerTypes';
import {
  AdvancedGfppTarget,
  GfppClickEventType,
} from '@wix/app-manifest-builder/dist/types/controller/gfpp/types/gfppCommonTypes';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

import { WIDGET_NAME } from '../../constants';

export const createCustomPanelOnClick =
  <T>({
    title,
    editorSDK,
    panelComp,
    helpId,
    height,
    width,
    initialData,
  }: {
    title: string;
    editorSDK: FlowEditorSDK;
    panelComp: string;
    height: number;
    width: number;
    initialData?: T;
    helpId?: string;
  }): AppManifestEventHandler<GfppClickEventType<AdvancedGfppTarget>> =>
  async ({ detail }) => {
    const isLocal = process.env.NODE_ENV === 'development';
    const controllerRef = (detail as any).controllerRef || detail.componentRef;

    return editorSDK.editor.openComponentPanel('', {
      title,
      url: getPanelUrl(
        WIDGET_NAME,
        panelComp,
        ...(isLocal ? [{ cdnPort: 3200 }] : [{}]),
      ),
      componentRef: detail.componentRef,
      initialData: { ...initialData, controllerRef },
      height,
      width,
      helpId,
      type: editorSDK.editor.PanelType.Settings,
    });
  };
