import { toMonitored } from '../editor/services/monitor';
import {
  addMyAccountWidget,
  addProfileCardWidget,
  addProfilePageBobWidget,
  alignProfileCardLayout,
  RoutesConfigurationServiceEditor,
} from '../editor/services';
import { Experiment } from '../constants';
import { PlatformContext } from '../types';

type InstallMemberPageParams = PlatformContext & {
  routeService: RoutesConfigurationServiceEditor;
};

export const installMemberPage = async ({
  editorSDK,
  options,
  appDefinitionId,
  flowAPI,
  routeService,
}: InstallMemberPageParams) => {
  await toMonitored('install.add-profile-page-bob-widget', () =>
    addProfilePageBobWidget({
      editorSDK,
      options,
      appDefinitionId,
      flowAPI,
    }),
  );

  const { experiments } = flowAPI;
  const shouldNotInstallProfileCard = experiments.enabled(
    Experiment.DoNotInitiallyInstallProfileCard,
  );

  if (shouldNotInstallProfileCard) {
    return toMonitored('install.add-my-account-widget', () =>
      addMyAccountWidget(editorSDK, routeService),
    );
  }

  await Promise.all([
    addProfileCardWidget(editorSDK),
    toMonitored('install.add-my-account-widget', () =>
      addMyAccountWidget(editorSDK, routeService),
    ),
  ]);
  await alignProfileCardLayout(editorSDK);
};
