import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import { getMenuItems } from '../editor-sdk-wrappers';
import { MenuId } from '../../constants';
import { updateMenuItems } from '../editor-sdk-wrappers/menu';
import { getMenuItemsWithReplacedMembersPath } from './utils';
import { EditorMenuItem } from '../../types';

export const getLoginBarItems = (editorSDK: FlowEditorSDK) => {
  return getMenuItems(editorSDK, MenuId.LoginMenu);
};

export const updateLoginBarItems = (
  editorSDK: FlowEditorSDK,
  items: EditorMenuItem[],
) => {
  return updateMenuItems(editorSDK, MenuId.LoginMenu, items);
};

export const removeLoginBarItem = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
) => {
  const loginBarMenuItems = await getLoginBarItems(editorSDK);

  const filteredLoginBarMenuItems = loginBarMenuItems.filter(
    (menuItem) => menuItem?.link?.itemId !== widgetId,
  );

  return updateLoginBarItems(editorSDK, filteredLoginBarMenuItems);
};

export const updateLoginBarLabel = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
  newLabel: string,
) => {
  const loginBarMenuItems = await getLoginBarItems(editorSDK);

  const updatedLoginBarMenuItems = loginBarMenuItems.map((menuItem) => {
    return menuItem?.link?.itemId === widgetId
      ? { ...menuItem, label: newLabel }
      : menuItem;
  });

  return updateLoginBarItems(editorSDK, updatedLoginBarMenuItems);
};

export const updateLoginBarMembersPath = async (
  editorSDK: FlowEditorSDK,
  currentPageUriSEO: string,
  newPageUriSEO: string,
) => {
  const loginBarMenuItems = await getLoginBarItems(editorSDK);

  const updatedLoginBarMenuItems = getMenuItemsWithReplacedMembersPath(
    loginBarMenuItems,
    currentPageUriSEO,
    newPageUriSEO,
  );

  return updateLoginBarItems(editorSDK, updatedLoginBarMenuItems);
};
