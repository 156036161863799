import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import type {
  EditorMenuItem,
  RouteConfiguration,
  RoutesConfigurationServiceEditor,
  SortableListItem,
} from '../../../types';

import { getMembersSubMenuItems } from '../../services';
import { getRoutes } from '../../controller/controllers-routes';
import { log } from '../../services/monitor';

const NOT_REMOVABLE_WIDGETS = [
  WidgetId.MyAccount,
  WidgetId.Notifications,
  WidgetId.Settings,
];

const getFollowersTabsData = (
  routes: RouteConfiguration[],
): SortableListItem | null => {
  const followersRoute = routes.find(
    (route) => route.widgetId === WidgetId.FollowingFollowers,
  );

  if (!followersRoute) {
    return null;
  }

  return {
    id: followersRoute.widgetId,
    label: 'app.settings.manage.menu.panel.followers.title',
    value: 'app.settings.manage.menu.panel.followers.value',
    selected: false,
    draggable: false,
    disableEditing: true,
  };
};

const getRouteByWidgetId = (widgetId: WidgetId, routes: RouteConfiguration[]) =>
  routes.find((route) => widgetId === route.widgetId);

const logMissingDataWarning = (extra: object) => {
  log(
    'Open Manage and Navigate panel: menu and routes are out of sync / missing data',
    {
      extra,
    },
  );
};

const createSortableListItem = (
  widgetId: WidgetId,
  label: string,
  isPrivate: boolean,
) => ({
  id: widgetId,
  label,
  value: isPrivate
    ? 'app.settings.manage.menu.panel.item.subtitle.private'
    : 'app.settings.manage.menu.panel.item.subtitle.public',
  selected: false,
  removable: !NOT_REMOVABLE_WIDGETS.includes(widgetId),
});

const toManageAndNavigatePanelInitialData = (
  routes: RouteConfiguration[],
  menuItems: EditorMenuItem[],
): SortableListItem[] => {
  let shouldLogDataWarning = false;

  const panelInitialData = menuItems.reduce<SortableListItem[]>(
    (acc, menuItem) => {
      const widgetIdFromMenuItem = menuItem?.link?.itemId as WidgetId;
      const routeItem = getRouteByWidgetId(widgetIdFromMenuItem, routes);
      const widgetId = widgetIdFromMenuItem ?? routeItem?.widgetId;

      if (!routeItem || !widgetId) {
        shouldLogDataWarning = true;
      }

      if (!widgetId) {
        return acc;
      }

      acc.push(
        createSortableListItem(widgetId, menuItem.label, !!routeItem?.private),
      );
      return acc;
    },
    [],
  );

  if (shouldLogDataWarning) {
    logMissingDataWarning({ routes, menuItems });
  }

  return panelInitialData;
};

export const getManageAndNavigatePanelInitialData = async (
  editorSDK: FlowEditorSDK,
  routeService: RoutesConfigurationServiceEditor,
) => {
  const routes = await getRoutes(editorSDK, routeService);
  const menuItems = await getMembersSubMenuItems(editorSDK);

  const items = toManageAndNavigatePanelInitialData(routes, menuItems);
  const followersItem = getFollowersTabsData(routes);

  return { items, followersItem };
};
