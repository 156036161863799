import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { MEMBERS_AREA } from '@wix/app-definition-ids';

import { Experiment } from '../constants';
import { log } from '../editor/services/monitor';

export const uninstallIfProvisionedWithoutMembersArea = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
) => {
  const enableUninstallation = flowAPI.experiments.enabled(
    Experiment.CleanUpBobWhenMAIsDeleted,
  );

  if (!enableUninstallation) {
    return { uninstalled: false };
  }

  const isMembersAreaPlatformInstalled =
    await editorSDK.application.isApplicationInstalled('', {
      appDefinitionId: MEMBERS_AREA,
    });

  if (!isMembersAreaPlatformInstalled) {
    log(
      'Members Area Page is provisioned without Members Area Platform - it will be deleted on editor ready',
    );
    await editorSDK.application.uninstall('', { openConfirmation: false });
    return { uninstalled: true };
  }

  return { uninstalled: false };
};
