import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { getRoutes } from '../controller/controllers-routes';
import { getProfilePageBobWidgetRef } from './page-ref';
import { getWidgetSlots } from './slots';
import { getMultiStateBoxStatesWidgetsIdsToStateMap } from './multi-state-box';
import {
  RoutesConfigurationServiceEditor,
  WidgetPluginPointer,
} from '../../types';

export const getPublicApplications = async (
  editorSDK: FlowEditorSDK,
  routeService: RoutesConfigurationServiceEditor,
) => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const routes = await getRoutes(editorSDK, routeService);
  if (!routes?.length) {
    return [];
  }

  const widgetSlots = await getWidgetSlots(editorSDK, widgetRef);
  const widgetIdStateMap = await getMultiStateBoxStatesWidgetsIdsToStateMap(
    editorSDK,
  );
  if (!widgetIdStateMap) {
    return [];
  }

  return widgetSlots.reduce<WidgetPluginPointer[]>((publicRoutes, slot) => {
    if (!slot.pluginInfo) {
      return publicRoutes;
    }
    const { appDefinitionId, widgetId } = slot.pluginInfo;

    const routeState = widgetIdStateMap[slot.pluginInfo.widgetId];
    const route = routes.find(({ state }) => state === routeState);
    if (!route || route.private === true) {
      return publicRoutes;
    }

    return [...publicRoutes, { appDefinitionId, widgetId }];
  }, []);
};
