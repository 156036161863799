import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { TpaPageLink } from '@wix/document-services-types';

import { Experiment } from '../../constants';
import { globalAppState } from './global-app-state';
import { EditorMenuItem } from '../../types';
import { getRoutesFromGlobalController } from '../controller/global-controller';

export const getMenuItemsWithReplacedMembersPath = (
  items: EditorMenuItem[],
  currentPageUriSEO: string,
  newPageUriSEO: string,
): EditorMenuItem[] => {
  return items.map((menuItem) => {
    if (!menuItem.link?.path) {
      return menuItem;
    }

    const linkWithUpdatedPath: TpaPageLink = {
      ...menuItem.link,
      path: menuItem.link.path.replace(currentPageUriSEO, newPageUriSEO),
    };

    return {
      ...menuItem,
      link: linkWithUpdatedPath,
    };
  });
};

export const shouldUseNewRoutesService = async (editorSDK: FlowEditorSDK) => {
  const flowAPI = globalAppState.getFlowAPI();
  const useNewRoutesService = flowAPI?.experiments.enabled(
    Experiment.UseNewRoutesService,
  );

  if (!useNewRoutesService) {
    return false;
  }

  const routes = await getRoutesFromGlobalController(editorSDK);
  return !routes.length;
};
