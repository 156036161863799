import type { ExportsFn } from '@wix/platform-editor-sdk';
import type { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';

import { createPublicAPI } from './editor-app/api-exports/public-api';
import { createPrivateAPI } from './editor-app/api-exports/private-api';
import { createEditorEventsHandlers } from './editor-app/api-exports/editor-events';
import { initializeMonitoring } from './editor/services/monitor';

import { globalAppState } from './editor/services';
import { createAppManifest, monitoredEditorReady } from './editor-app';

const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  _contextParams,
  flowAPI,
) => {
  return createAppManifest(options, editorSDK, _contextParams, flowAPI);
};

const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  options,
  flowAPI,
) => {
  initializeMonitoring(flowAPI, options);
  globalAppState.setFlowAPI(flowAPI);
  globalAppState.setIsClassicEditor(options.origin.type === 'CLASSIC');

  await monitoredEditorReady(editorSDK, appDefinitionId, options, flowAPI);
};

const _exports: ExportsFn = (editorSDK, contextParams) => {
  return {
    public: createPublicAPI(editorSDK, contextParams),
    private: createPrivateAPI(editorSDK, contextParams),
    editor: createEditorEventsHandlers(editorSDK),
  };
};

export { editorReady, getAppManifest, _exports as exports };
