import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  TFunction,
} from '@wix/yoshi-flow-editor';
import type {
  AddComponentHandler,
  ElementData,
  RemoveComponentHandler,
} from '@wix/platform-editor-sdk';

import type {
  EditorContextServices,
  ManageAndNavigatePanelInitialData,
} from '../../types';
import { LayoutPanelInitialData } from '../../types';
import { getManageAndNavigatePanelInitialData } from './utils';
import { createCustomPanelOnClick } from './create-panel';
import { ElementId } from '../../constants';
import { toMonitored } from '../services/monitor';

import {
  collapseRefComponent,
  expandRefComponentByRole,
  refreshApp,
} from '../editor-sdk-wrappers';
import {
  addProfileCardWidget,
  alignProfileCardLayout,
  removeProfileCardWidget,
  withRetry,
} from '../services';

type OpenManageAndNavigatePanelOptions = {
  editorSDK: FlowEditorSDK;
  event: CustomEvent;
  t: TFunction;
};

export const openPresetPanel = async (
  editorSDK: FlowEditorSDK,
  event: CustomEvent,
  t: TFunction,
) => {
  const viewport = await editorSDK.editor.info.getCurrentViewport();

  return createCustomPanelOnClick<LayoutPanelInitialData>({
    title: t('app.settings.layout.preset.title'),
    editorSDK,
    panelComp: 'LayoutPanel',
    height: 200,
    width: 288,
    helpId: '0899bd6c-4337-4af8-bac8-bde9dc77d6c2',
    initialData: { viewportType: viewport.type },
  })(event);
};
export const openManageAndNavigatePanel = async (
  { editorSDK, event, t }: OpenManageAndNavigatePanelOptions,
  { routeService }: Pick<EditorContextServices, 'routeService'>,
) => {
  const initialData = await toMonitored(
    'manage-and-navigate-panel.get-initial-data',
    () =>
      withRetry(
        () => getManageAndNavigatePanelInitialData(editorSDK, routeService),
        { methodName: 'getManageAndNavigatePanelInitialData' },
      ),
  );

  return createCustomPanelOnClick<ManageAndNavigatePanelInitialData>({
    title: t('app.settings.manage.menu.panel.title'),
    editorSDK,
    panelComp: 'ManageAndNavigatePanel',
    height: 521,
    width: 288,
    helpId: 'f99c3e77-0303-46f3-a5eb-23e565ecdcb8',
    initialData,
  })(event);
};

const getElementsData = (t: TFunction): ElementData[] => [
  {
    label: t('app.settings.elements.panel.member-card'),
    identifier: { role: ElementId.HeaderContainer },
    index: 0,
  },
  {
    label: t('app.settings.elements.panel.member-menu'),
    identifier: { role: ElementId.MenuContainer },
    index: 1,
  },
];

export const openDisplayPanel = async (
  flowAPI: EditorScriptFlowAPI,
  editorSDK: FlowEditorSDK,
  event: CustomEvent,
) => {
  const {
    translations: { t },
  } = flowAPI;
  const { componentRef } = event.detail;

  const removeHandler: RemoveComponentHandler = async (compRef, identifier) => {
    if (identifier.role === ElementId.HeaderContainer) {
      await removeProfileCardWidget(editorSDK);
    }
    await collapseRefComponent(editorSDK, compRef);
    return [identifier];
  };

  const addHandler: AddComponentHandler = async (identifier) => {
    await expandRefComponentByRole(editorSDK, identifier.role);
    if (identifier.role === ElementId.HeaderContainer) {
      await addProfileCardWidget(editorSDK);
      await alignProfileCardLayout(editorSDK);
    }
    refreshApp(editorSDK);
    return [identifier];
  };

  return editorSDK.editor.openElementsPanel('', {
    widgetRef: componentRef,
    categoriesData: [],
    helpId: '688323a1-0974-41f2-aa4d-095d18f99625',
    elementsData: getElementsData(t as TFunction),
    addComponentHandler: addHandler,
    removeComponentHandler: removeHandler,
  });
};
