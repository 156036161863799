import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { AppExposedApis } from '@wix/platform-editor-sdk';
import { MEMBERS_AREA } from '@wix/app-definition-ids';

export const createEditorEventsHandlers = (
  editorSDK: FlowEditorSDK,
): AppExposedApis['editor'] => ({
  // This app should be deleted from "beforeRemoveApp" handler in the umbrella platform app
  // However uninstallation gets stuck when done from there for some reason
  removeAppCompleted: async ({ appDefinitionId }) => {
    if (appDefinitionId === MEMBERS_AREA) {
      await editorSDK.application.uninstall('', { openConfirmation: false });
    }
  },
});
