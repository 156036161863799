import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import {
  AddedWidgetConfig,
  RouteConfiguration,
  RoutesConfigurationServiceEditor,
} from '../../types';
import { getRoutes, updateRoutesConfig } from './controllers-routes';

type AddRoutesInAppPropertiesProps = {
  editorSDK: FlowEditorSDK;
  addedWidgetsConfigs: AddedWidgetConfig[];
  routeService: RoutesConfigurationServiceEditor;
};

const getIsRouteAlreadyAdded = (
  currentRoutes: RouteConfiguration[] | undefined,
  newPath: string,
  newState: string,
) =>
  !!currentRoutes?.some(
    ({ path, state }) => path === newPath || state === newState,
  );

const getNewRoutes = async (
  editorSDK: FlowEditorSDK,
  currentRoutes: RouteConfiguration[] | undefined,
  addedWidgetsConfigs: AddedWidgetConfig[],
) => {
  return addedWidgetsConfigs.reduce<RouteConfiguration[]>(
    (previousValue, currentValue) => {
      if (!currentValue.state) {
        return previousValue;
      }

      const isRouteAlreadyAdded = getIsRouteAlreadyAdded(
        currentRoutes,
        currentValue.path,
        currentValue.state,
      );

      if (isRouteAlreadyAdded) {
        return previousValue;
      }

      // TODO: don't save values which has default values to save space
      previousValue.push({
        path: currentValue.path,
        state: currentValue.state,
        vfr: currentValue.vfr,
        home: currentValue.home,
        private: currentValue.private,
        widgetId: currentValue.widgetId,
      });

      return previousValue;
    },
    currentRoutes ?? [],
  );
};

export const addRoutesInAppProperties = async ({
  editorSDK,
  addedWidgetsConfigs,
  routeService,
}: AddRoutesInAppPropertiesProps) => {
  const currentRoutes = await getRoutes(editorSDK, routeService);

  const newRoutes = await getNewRoutes(
    editorSDK,
    currentRoutes,
    addedWidgetsConfigs,
  );

  const updatedRoutes = newRoutes ?? currentRoutes ?? [];

  return updateRoutesConfig(editorSDK, updatedRoutes);
};
