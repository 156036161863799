import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import { CommonSyncDataProps } from '../../types';
import { determineSyncOperations } from './sync-utils';
import {
  getRoutesFromGlobalController,
  removeRoutesFromGlobalController,
  updateGlobalControllerWithRoutes,
} from '../../editor/controller/global-controller';
import { getInstalledPluginsSlots } from '../../editor/services/slots';
import { buildRoutesFromSlots } from '../../editor/controller/routes-utils';
import { log } from '../../editor/services/monitor';

type SyncRoutesWithMenuItems = CommonSyncDataProps & {
  widgetIdsFromRoutes: WidgetId[];
};

const addRoutesToGlobalController = async (
  editorSDK: FlowEditorSDK,
  widgetIdsToAdd: WidgetId[],
) => {
  const slots = await getInstalledPluginsSlots(editorSDK);
  const slotsToAdd = slots.filter(({ pluginInfo }) =>
    widgetIdsToAdd.includes(pluginInfo!.widgetId),
  );

  if (slotsToAdd.length) {
    const routesToAdd = await buildRoutesFromSlots(editorSDK, slotsToAdd);
    const currentRoutes = await getRoutesFromGlobalController(editorSDK);

    return updateGlobalControllerWithRoutes(editorSDK, [
      ...currentRoutes,
      ...routesToAdd,
    ]);
  }
};

export const syncRoutes = async ({
  editorSDK,
  widgetIdsFromRoutes,
  menuBasedWidgetIds,
}: SyncRoutesWithMenuItems) => {
  const { idsToAdd, idsToRemove } = determineSyncOperations({
    sourceOfTruth: menuBasedWidgetIds,
    syncTarget: widgetIdsFromRoutes,
  });

  if (idsToRemove.length) {
    await removeRoutesFromGlobalController(editorSDK, idsToRemove);
  }

  if (idsToAdd.length) {
    await addRoutesToGlobalController(editorSDK, idsToAdd);
  }

  if (idsToAdd.length || idsToRemove.length) {
    log('editorReady: Syncing routes with menu items', {
      extra: {
        idsToAdd,
        idsToRemove,
        widgetIdsFromRoutes,
        menuBasedWidgetIds,
      },
    });
  }
};
