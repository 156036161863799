import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import {
  removeMembersSubMenuItem,
  updateMembersSubMenuItemLabel,
  updateMembersSubMenuMembersPath,
} from './members-sub-menu';
import {
  removeLoginBarItem,
  updateLoginBarLabel,
  updateLoginBarMembersPath,
} from './login-bar-menu';

const removeWidgetMenusItems = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
) => {
  return Promise.all([
    removeMembersSubMenuItem(editorSDK, widgetId),
    removeLoginBarItem(editorSDK, widgetId),
  ]);
};

export const removeWidgetsMenusItems = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  for (const widgetId of widgetsIds) {
    await removeWidgetMenusItems(editorSDK, widgetId);
  }
};

export const updateMenusItemLabel = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
  newLabel: string,
) => {
  await Promise.all([
    updateMembersSubMenuItemLabel(editorSDK, widgetId, newLabel),
    updateLoginBarLabel(editorSDK, widgetId, newLabel),
  ]);
};

export const updateMenusMembersPath = async (
  editorSDK: FlowEditorSDK,
  currentPageUriSEO: string,
  newPageUriSEO: string,
) => {
  return Promise.all([
    updateMembersSubMenuMembersPath(
      editorSDK,
      currentPageUriSEO,
      newPageUriSEO,
    ),
    updateLoginBarMembersPath(editorSDK, currentPageUriSEO, newPageUriSEO),
  ]);
};
