import { Experiments, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { getWidgetsIdsFromGlobalControllerRoutes } from '../../editor/controller/global-controller';
import { getWidgetsIdsFromSlots } from '../../editor/services/slots';
import { Experiment } from '../../constants';
import { log, toMonitored } from '../../editor/services/monitor';
import { getWidgetIdsFromSubMenu } from '../../editor/services/members-sub-menu';
import { syncRoutes } from './sync-routes';
import { syncPlugins } from './sync-plugins';

import {
  monitoredFixGlobalControllerMissingRoutesData,
  shouldFixGlobalControllerMissingRoutesData,
} from './global-controller-fixer';
import { WidgetId } from '@wix/members-area-app-definitions';

const getWidgetsIds = async (editorSDK: FlowEditorSDK) => {
  const [widgetIdsFromSubMenu, widgetIdsFromRoutes, widgetIdsFromSlots] =
    await Promise.all([
      getWidgetIdsFromSubMenu(editorSDK),
      getWidgetsIdsFromGlobalControllerRoutes(editorSDK),
      getWidgetsIdsFromSlots(editorSDK),
    ]);

  // Followers item is not in the sub menu
  if (widgetIdsFromSlots.includes(WidgetId.FollowingFollowers)) {
    widgetIdsFromSubMenu.push(WidgetId.FollowingFollowers);
  }

  return {
    menuBasedWidgetIds: widgetIdsFromSubMenu,
    widgetIdsFromRoutes,
    widgetIdsFromSlots,
  };
};

export const syncData = async (editorSDK: FlowEditorSDK) => {
  const { menuBasedWidgetIds, widgetIdsFromRoutes, widgetIdsFromSlots } =
    await getWidgetsIds(editorSDK);

  await syncPlugins({
    editorSDK,
    widgetIdsFromSlots,
    menuBasedWidgetIds,
  });

  await syncRoutes({
    editorSDK,
    widgetIdsFromRoutes,
    menuBasedWidgetIds,
  });
};

const monitoredSyncData = (editorSDK: FlowEditorSDK) => {
  return toMonitored('editorReady.sync-data', () => syncData(editorSDK));
};

export const maybeSanitizeData = async (
  editorSDK: FlowEditorSDK,
  experimentsAPI: Experiments,
) => {
  try {
    if (
      await shouldFixGlobalControllerMissingRoutesData(
        editorSDK,
        experimentsAPI,
      )
    ) {
      await monitoredFixGlobalControllerMissingRoutesData(editorSDK);
    }

    if (experimentsAPI.enabled(Experiment.SyncDataWithMenuItems)) {
      await monitoredSyncData(editorSDK);
    }
  } catch (error) {
    log('Warning: Failed to sanitize data in editor ready', {
      extra: {
        error: typeof error === 'string' ? error : (error as Error).message,
      },
    });
  }
};
