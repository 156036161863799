import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';
import { PageRef } from '@wix/platform-editor-sdk';

import { getRoutes } from '../controller/controllers-routes';
import { getMemberPageUriSEO } from '../editor-sdk-wrappers/pages';
import { Nullable, RoutesConfigurationServiceEditor } from '../../types';
import { SlugPlaceholder } from '../../constants';
import { getMembersAreaPageId, getMembersAreaPageRef } from './page';

export const navigateToSection = async (
  editorSDK: FlowEditorSDK,
  routeService: RoutesConfigurationServiceEditor,
  widgetId: WidgetId,
) => {
  const routes = await getRoutes(editorSDK, routeService);
  const routeConfig = routes.find((route) => route.widgetId === widgetId);

  if (!routeConfig) {
    throw new Error(`[MA V2]: Could not find route for widgetId: ${widgetId}`);
  }

  const membersAreaPageId = await getMembersAreaPageId(editorSDK);

  const suffix = await getMemberPageUriSEO(editorSDK);

  return editorSDK.pages.navigateTo('', {
    pageLink: {
      innerRoute: `./${suffix}/${SlugPlaceholder.My}/${routeConfig.path}`,
      routerId: membersAreaPageId,
      type: 'DynamicPageLink',
      // @ts-expect-error missing type from platform
      isTpaRoute: true,
    },
  });
};

export const isCurrentPageMembersAreaPage = (
  membersAreaPageRef: PageRef,
  currentPageRef: Nullable<PageRef>,
) => {
  return (
    currentPageRef?.id === membersAreaPageRef.id &&
    currentPageRef?.type === membersAreaPageRef.type
  );
};

export const maybeNavigateToMemberPage = async (editorSDK: FlowEditorSDK) => {
  const pageRef = await getMembersAreaPageRef(editorSDK);

  if (!pageRef) {
    throw new Error('Members Page ref missing navigating to Member Page');
  }

  const currentPageRef = await editorSDK.pages.getCurrent('');
  if (!isCurrentPageMembersAreaPage(pageRef, currentPageRef)) {
    await editorSDK.pages.navigateTo('', { pageRef });
  }
};
